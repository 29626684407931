<template>
  <div
    class="z-[2001] fixed w-[90vw] top-0 md:w-[35vw] bg-neutral-lightest h-full right-0"
    @click.self="closeMenu"
  >
    <div
      class="basket-content z-20 h-full pb-32 relative"
    >
      <div class="select-none cursor-pointer ml-auto w-[fit-content] absolute right-24 top-20 sm:top-24" @click="closeMenu">
        <img
          class="h-24 w-24"
          src="/zynicons/close.svg"
          height="25"
          width="25"
          alt="close"
        >
      </div>
      <div class="h-full flex flex-col">
        <div
          class="pt-24 px-24 pb-16 border-neutral-light border-b items-center type-headline-sm sm:pb-24"
        >
          <div class="flex mb-12 sm:mb-16">
            {{
              $lang(
                'shoppingCartResources',
                purchaseType === PurchaseType.Subscription
                  ? 'subscriptionBasketHeader'
                  : 'basketHeader'
              )
            }}
            <span class="font-normal ml-8">
              {{
                `(${amountOfItems} ${
                  amountOfItems == 1
                    ? $lang('shoppingCartResources', 'item')
                    : $lang('shoppingCartResources', 'items')
                })`
              }}
            </span>
          </div>
          <div v-if="showConflictingCartNotices">
            <div
              v-for="(notice, index) in conflictingCartNotices"
              :key="index"
              class="p-12 rounded-md mb-12 flex items-center"
              :style="`background-color: ${notice.backgroundColor}`"
            >
              <img
                v-if="notice.icon"
                class="mr-8"
                :src="notice.icon"
                height="24"
                alt=""
              >
              <div class="wysiwyg type-xs text-brand-dark" v-html="notice.text" />
            </div>
          </div>
          <div
            v-for="(notice, index) in cartNotices"
            :key="index"
            class="p-12 rounded-md mb-12 flex items-center"
            :style="`background-color: ${notice.backgroundColor}`"
          >
            <img
              v-if="notice.icon"
              class="mr-8"
              :src="notice.icon"
              height="24"
              alt=""
            >
            <div class="wysiwyg type-xs text-brand-dark" v-html="notice.text" />
          </div>
          <div class="flex justify-between">
            <div
              class="type-xs flex-1 py-0 md:py-[11px] h-40 md:h-auto animate-all border border-neutral-light type-tag-xs text-center cursor-pointer flex items-center justify-center mr-6 sm:mr-8"
              :class="{
                'shadow-border-black !border-black': activePurchaseType === PurchaseType.Standard
              }"
              @click="setActiveCart(PurchaseType.Standard)"
            >
              <span>Köp en gång</span>
            </div>
            <div
              class="type-xs flex-1 py-0 md:py-[11px] h-40 md:h-auto animate-all border border-neutral-light type-tag-xs text-center cursor-pointer flex items-center justify-center ml-6 sm:ml-8"
              :class="{
                'shadow-border-black !border-black': activePurchaseType === PurchaseType.Subscription
              }"
              @click="setActiveCart(PurchaseType.Subscription)"
            >
              <span>Prenumerera</span>
            </div>
          </div>
          <div v-if="activePurchaseType === PurchaseType.Subscription && amountOfItems > 0" class="mt-16 sm:mt-24">
            <div class="type-headline-xs text-brand-dark mb-12">
              {{ $lang('checkoutResources', 'autoshipHeading') }}
            </div>
            <div class="border border-neutral-light overflow-hidden">
              <Selector
                :items="subscriptionInterval"
                @changed="setSubscriptionInterval"
              />
            </div>
          </div>
        </div>
        <div class="overflow-y-scroll h-full">
          <div v-if="amountOfItems > 0">
            <div class="px-24">
              <BasketItem
                v-for="item in items"
                :key="item.id"
                :item="item"
                :purchase-type="activePurchaseType"
                :show-discount="true"
                @loading-price="setPriceIsLoading"
              />
            </div>
          </div>
          <div v-else class="py-16 px-24">
            {{ $lang('shoppingCartResources', 'emptyCart') }}
          </div>
        </div>
        <div v-if="amountOfItems > 0" class="sticky bottom-0 bg-white">
          <transition name="fade-in">
            <ErrorBar
              v-if="shouldShowErrorBar"
              :is-error="true"
              :text="errorBarText"
              :is-local-error-message="true"
              @close-snack-bar="$emit('close-error-bar')"
            />
          </transition>
          <div class="bg-white px-24 border-t border-neutral-light">
            <div class="py-16">
              <div class="flex justify-between type-xs items-center mb-8">
                <div>{{ $lang('shoppingCartResources', 'deliveryHeadline') }}</div>
                <div
                  class="type-headline-sm font-normal"
                  :class="{
                    'opacity-50': priceLoading,
                  }"
                >
                  {{ cartData.delivery }}
                </div>
              </div>
              <div class="flex justify-between type-xs items-center mb-16">
                <div>{{ $lang('shoppingCartResources', 'subTotalHeadline') }}</div>
                <div
                  class="type-headline-sm"
                  :class="{
                    'opacity-50': priceLoading,
                  }"
                >
                  {{ cartData.subTotal }}
                </div>
              </div>
              <div>
                <Button
                  :link-url="checkoutUrl"
                  :theme="'primary'"
                  :text="$lang('shoppingCartResources', 'proceedToCheckout')"
                  :is-block="true"
                  class="cart-btn"
                />
                <div class="mt-24 flex justify-center md:mt-32">
                  <img
                    v-for="logo in paymentLogos"
                    :key="'cartPaymentLogo-' + logo"
                    class="max-h-18 sm:max-h-24 last:mr-0 mr-24"
                    :src="logo"
                    height="24"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import BasketItem from './BasketItem.vue';
import Button from './globals/Button.vue';
import { PurchaseType } from '~/models/api-types';
import { useCartStore } from '~/store/cart';
import { useUiStore } from '~/store/ui';
import Selector from './form-elements/Selector.vue';

const config = useGlobalContentStore().config;
const cartStore = useCartStore();
const uiStore = useUiStore();

const { apiGet } = useApiFetch();
const { $lang } = useNuxtApp();

const props = defineProps<{
  purchaseType?: PurchaseType,
  shouldShowErrorBar?: boolean,
  errorBarText?: string,
}>();

const priceLoading = ref(false);
const lastUpdatedCart = ref(cartStore.lastUpdatedCart as PurchaseType || PurchaseType.Standard);
const activePurchaseType = ref(lastUpdatedCart);

const items = computed(() => {
  return activePurchaseType.value === PurchaseType.Subscription
    ? cartStore.subscriptionItems
    : cartStore.items;
});

const cartData = computed(() => {
  return activePurchaseType.value === PurchaseType.Subscription
    ? cartStore.subscriptionCart
    : cartStore.cart;
});

const amountOfItems = computed(() => {
  return items.value?.length;
});

const paymentLogos = computed(() => {
  const images = config.currentMarket?.cartPaymentLogos || [];
  return images;
});

const checkoutUrl = computed(() => {
  return cartData.value?.checkoutUrl || '/';
});

const conflictingCartNotices = computed(() => {
  return config.currentMarket?.conflictingCartNotices ?? [];
});

const showConflictingCartNotices = computed(() => {
  const standardItems = cartStore.standardCart?.items || [];
  const subscriptionItems = cartStore.subscriptionCart?.items || [];
  if (activePurchaseType.value === PurchaseType.Subscription && standardItems.length > 0) return true;
  if (activePurchaseType.value === PurchaseType.Standard && subscriptionItems.length > 0) return true;
  if (standardItems.length > 0 && subscriptionItems.length > 0) return true;

  return false;
});

const cartNotices = computed(() => {
  if (activePurchaseType.value === PurchaseType.Subscription) {
    return config.currentMarket?.subscriptionCartNotices;
  } else {
    return config.currentMarket?.standardCartNotices;
  }
});

const setActiveCart = (purchaseType: PurchaseType) => {
  activePurchaseType.value = purchaseType;
  cartStore.lastUpdatedCart = purchaseType;
};

const setPriceIsLoading = (isLoading: boolean) => {
  priceLoading.value = isLoading;
};

const subscriptionInterval = computed(() => {
  return cartStore.subscriptionCart?.subscriptionScheduleIntervals.map(
    (interval) => {
      return {
        isSelected: interval.isSelected,
        value: interval.value,
        label: interval.name,
      };
    }
  );
});

const setSubscriptionInterval = async(input: string) => {
  await cartStore.setSubscriptionInterval({
    interval: input,
    purchaseType: PurchaseType.Subscription,
  });
  window.dataLayer?.push({
    event: 'checkoutOption',
    ecommerce: {
      currencyCode: config.currentMarket?.currencyCode,
      checkout_option: {
        actionField: {
          step: 1,
          option: subscriptionInterval.value?.find(
            (s) => s.value.toString() === input
          )?.label,
          action: 'checkout_option',
        },
      },
    },
  });
};

const closeMenu = () => {
  uiStore.setShowCartMenu(false);
};
</script>
<style>
.basket-content {
  @apply flex flex-col;

  /* max-height: calc(100vh - 80px); */
  overflow: auto;
}
.basket-container:not(.basket-container--simple)::after {
  @apply bg-grey800 z-10 border-l border-t border-grey500;

  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -11px;
  right: var(--right-set);
  background: inherit;
  transform: rotate(45deg);
  border-top-width: 1.5px;
  border-left-width: 1.5px;
}

.cart-btn {
  a {
    @apply sm:!h-48;
  }
}

/*
.basket-container {
  max-height: calc(100% - 100px);

  @screen lg {
    max-height: calc(100% - 160px);
  }
} */
</style>
